import { Tag } from '../../types';
import { ROUTES } from '../../../../lib/ROUTES';
import { Button } from '../../../../components/Button';

interface Props {
  tags: Tag[];
}

const Tags = ({ tags }: Props): JSX.Element | null => {
  if (!tags.length) {
    return null;
  }

  const title = tags.length > 1 ? 'Теги' : 'Тег';

  return (
    <p>
      {tags.map(({ value, id, alias }, idx) => [
        idx === 0 ? `${title}: ` : ', ',
        <Button className="inline-block" key={id} to={ROUTES.TAG_FILTER.$buildPath({ params: { id: alias } })}>
          {value}
        </Button>,
      ])}
    </p>
  );
};

export { Tags };
