import { MediaPost } from '../../types';
import { CoverImage, CoverType } from '../../../../components/CoverImage';
import { Description } from './Description';
import { tw } from '../../../../styles/tw';
import { layout } from '../../../../styles/layout';

interface PostHeaderProps {
  mediaPost: MediaPost;
  className?: string;
}

const SpecialPostHeader = ({ mediaPost, className }: PostHeaderProps) => {
  const { cover, isCoverEnabled } = mediaPost;

  return isCoverEnabled ? (
    /* Some specials overlap it */
    <header className={tw(layout, 'relative z-content lg:mb-8 lg:flex lg:flex-row lg:gap-12', className)}>
      <div className="lg:flex lg:w-1/2">
        <Description mediaPost={mediaPost} className="lg:h-full" />
      </div>
      <div className="lg:flex lg:w-1/2">
        {cover ? (
          <CoverImage isPrioritized className="hidden lg:block lg:w-full" cover={cover} type={CoverType.Promo} />
        ) : null}
      </div>
    </header>
  ) : (
    /* Some specials overlap it */
    <header className={tw(layout, 'relative z-content flex flex-col items-stretch lg:mb-4', className)}>
      <Description mediaPost={mediaPost} className="lg:h-full" centerOnLg />
    </header>
  );
};

export { SpecialPostHeader };
