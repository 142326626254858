import { useEffect, useMemo } from 'react';
import { useTypedSearchParams } from 'react-router-typesafe-routes';
import { PostComment } from '../../../types';
import { AdvertisementId, useMultipleRepeats } from '../../../../../../components/Advertisement';
import { ChildrenOpenStateProvider } from './ChildrenOpenStateProvider';
import { CommentWithChildren } from './CommentWithChildren';
import { ROUTES } from '../../../../../../lib/ROUTES';
import { useLoginInfo } from '../../../../../../react-hooks/useLoginInfo';

interface CommentListProps {
  comments: PostComment[] | undefined;
}

const CommentList = ({ comments }: CommentListProps) => {
  const [{ targetCommentId }] = useTypedSearchParams(ROUTES.POST);

  const { user } = useLoginInfo();

  useEffect(() => {
    if (targetCommentId) {
      document.getElementById(targetCommentId)?.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  }, [targetCommentId]);

  const repeats = useMultipleRepeats([
    AdvertisementId.DesktopInCommentsBanner,
    AdvertisementId.DesktopInChildCommentsBanner,
    AdvertisementId.MobileInComments,
    AdvertisementId.MobileInChildComments,
  ]);

  const desktopAdIds = useMemo(
    () =>
      getAdvertisementIds(
        comments ?? [],
        repeats[AdvertisementId.DesktopInCommentsBanner],
        repeats[AdvertisementId.DesktopInChildCommentsBanner],
      ),
    [comments, repeats],
  );

  const mobileAdIds = useMemo(
    () =>
      getAdvertisementIds(
        comments ?? [],
        repeats[AdvertisementId.MobileInComments],
        repeats[AdvertisementId.MobileInChildComments],
      ),
    [comments, repeats],
  );

  return (
    <div className="mx-0 mb-6 mt-3.5 flex w-full flex-col items-stretch justify-center">
      {comments?.map((comment) => (
        <ChildrenOpenStateProvider key={comment.id} rootCommentId={comment.id}>
          <CommentWithChildren comment={comment} user={user} desktopAdIds={desktopAdIds} mobileAdIds={mobileAdIds} />
        </ChildrenOpenStateProvider>
      ))}
    </div>
  );
};

function getAdvertisementIds(
  comments: PostComment[],
  repeats: number | undefined,
  childRepeats: number | undefined,
): string[] {
  const outerIds = repeats ? comments.map((comment) => comment.id).filter((_, index) => !((index + 1) % repeats)) : [];
  const innerIds = getChildrenAdvertisementIds(comments, childRepeats);

  return [...outerIds, ...innerIds];
}

function getChildrenAdvertisementIds(comments: PostComment[], childRepeats: number | undefined): string[] {
  if (!childRepeats) {
    return [];
  }

  const childrenIds: string[] = [];

  comments.forEach((comment) => {
    const children = comment.children ?? [];

    if (children.length >= 4) {
      for (let j = 0; j < children.length; j += 1) {
        if (j === 1 || ((j - 1) % childRepeats === 0 && j < children.length - 2)) {
          const child = children[j];
          if (child) {
            childrenIds.push(child.id);
          }
        }
      }
    }
  });

  return childrenIds;
}

export { CommentList };
