import { useTypedParams } from 'react-router-typesafe-routes';
import { MediaPost } from '../../types';
import '@flame-frontend-utils/tiptap/styles/river/common.scss';
import '@flame-frontend-utils/tiptap/styles/river/view.scss';
import { CoverImage, CoverType } from '../../../../components/CoverImage';
import { PostBodyType, PostSpecies, PostStatus } from '../../../../lib/graphql.document';
import { Tags } from './Tags';
import { SpecialHtml } from './SpecialHtml';
import { FlameHtml } from './FlameHtml';
import { LINKED_DATA_POST_CLASS_NAME } from '../LinkedData';
import { VerstkaHtml } from './VerstkaHtml';
import { tw } from '../../../../styles/tw';
import { layout } from '../../../../styles/layout';
import { Grid } from './Grid';
import { ButtonVariant } from '../../../../components/Button';
import { CreatePostButton } from '../../../../components/CreatePostButton';
import { ROUTES } from '../../../../lib/ROUTES';

interface PostContentProps {
  mediaPost: MediaPost;
  className?: string;
}

const PostContent = ({ mediaPost, className }: PostContentProps) => {
  const { slug } = useTypedParams(ROUTES.POST);

  const {
    body,
    cover,
    isCoverEnabled,
    tags,
    status,
    bodyType,
    isAdvertisementEnabled,
    disabledAdvertisements,
    species,
  } = mediaPost;

  const isSpecial = [PostBodyType.Legacy, PostBodyType.Verstka].includes(bodyType);
  const isPublished = status === PostStatus.Published;

  const Html =
    bodyType === PostBodyType.Legacy ? SpecialHtml : bodyType === PostBodyType.Verstka ? VerstkaHtml : FlameHtml;

  return (
    /**
     * Post content goes first in DOM, but we need it in the second column. When element position changes due to changes
     * in height, browsers try to keep it in viewport by adjusting scroll position. When there are multiple elements,
     * browsers seem to prioritize elements based on position in DOM.
     */
    <div className={tw('order-2 lg:flex lg:flex-col', className)}>
      {cover && isCoverEnabled ? (
        <CoverImage
          isPrioritized={mediaPost.slug === slug}
          className={tw(isSpecial && layout, 'mb-5', isSpecial && 'lg:hidden')}
          cover={cover}
          type={CoverType.Promo}
          displaySource
          allowOpenInNewTab
        />
      ) : null}
      {species === PostSpecies.Blog ? (
        <div className="-mx-5 mb-5 flex flex-col items-center justify-between gap-4 bg-cta-background p-5 text-center text-blood md:mx-0 md:flex-row md:gap-5 md:px-5 md:text-left">
          <p className="whitespace-pre-line">Это пост читателя Сплетника,{'\n'}начать писать на сайте можешь и ты</p>
          <CreatePostButton
            className="whitespace-nowrap bg-cta-btn-background"
            variant={ButtonVariant.Outlined}
            buttonAlwaysVisible
          />
        </div>
      ) : null}
      <Html
        body={body ?? ''}
        className={LINKED_DATA_POST_CLASS_NAME}
        disableAds={!isAdvertisementEnabled || disabledAdvertisements}
      />
      {isSpecial ? (
        <Grid className={layout}>
          <div />
          <div>
            <div className={tw('mt-auto lg:pb-0', isPublished ? 'mb-6 pb-0' : 'mb-0 pb-4.5')}>
              <Tags tags={tags} />
            </div>
          </div>
        </Grid>
      ) : (
        <div className={tw('mt-auto lg:pb-0', isPublished ? 'mb-6 pb-0' : 'mb-0 pb-4.5')}>
          <Tags tags={tags} />
        </div>
      )}
    </div>
  );
};

export { PostContent };
