import { RefObject, useState } from 'react';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { createPortal } from 'react-dom';
import { useLayoutEffectWithoutSsrWarning } from '../../react-hooks/useLayoutEffectWithoutSsrWarning';
import { Advertisement, AdvertisementId, useRepeats } from '../../components/Advertisement';
import { getContentLengthArray } from './lib/getContentLenghtArray';
import { getInjectionIndicesArray } from './lib/getInjectionIndicesArray';
import { tw } from '../../styles/tw';

function useContentAdvertisements(contentRef: RefObject<HTMLElement>, disabled: boolean | string[] = false) {
  const [portals, setPortals] = useState<JSX.Element[]>([]);

  const desktopRepeats = useRepeats(AdvertisementId.DesktopInContentBanner);
  const mobileRepeats = useRepeats(AdvertisementId.MobileInContent);

  useLayoutEffectWithoutSsrWarning(() => {
    const rootElement = contentRef.current;
    assertIsDefined(rootElement);

    if (
      desktopRepeats &&
      mobileRepeats &&
      !(
        isDisabled(disabled, AdvertisementId.DesktopInContentBanner) &&
        isDisabled(disabled, AdvertisementId.MobileInContent) &&
        isDisabled(disabled, AdvertisementId.MobileAfterCover)
      )
    ) {
      const lengths = getContentLengthArray(rootElement);
      const injectionIndicesDesktop = getInjectionIndicesArray(
        lengths,
        desktopRepeats,
        PUBLIC_CONFIG.CHARACTERS_BETWEEN_ADS_DESKTOP,
      );
      const injectionIndicesMobile = getInjectionIndicesArray(
        lengths,
        mobileRepeats,
        PUBLIC_CONFIG.CHARACTERS_BETWEEN_ADS_MOBILE,
      );

      const desktopAdElements = [...rootElement.children].filter((_, index) => injectionIndicesDesktop.includes(index));
      const mobileAdElements = [...rootElement.children].filter((_, index) => injectionIndicesMobile.includes(index));

      const nextPortals: JSX.Element[] = [];

      desktopAdElements.forEach((desktopAdElement, index) => {
        const adContainer = document.createElement('div');
        adContainer.classList.add(...adWrapper.split(' '));
        adContainer.classList.add(...displayOnDesktop.split(' '));

        desktopAdElement.after(adContainer);

        nextPortals.push(
          createPortal(
            // eslint-disable-next-line react/no-array-index-key
            <Advertisement id={AdvertisementId.DesktopInContentBanner} key={index} />,
            adContainer,
          ),
        );
      });

      {
        const adContainer = document.createElement('div');
        adContainer.classList.add(...adWrapper.split(' '));
        adContainer.classList.add(...displayOnMobile.split(' '));

        rootElement.prepend(adContainer);

        nextPortals.push(
          createPortal(
            <Advertisement id={AdvertisementId.MobileAfterCover} key={AdvertisementId.MobileAfterCover} />,
            adContainer,
          ),
        );
      }

      mobileAdElements.forEach((mobileAdElement, index) => {
        const adContainer = document.createElement('div');
        adContainer.classList.add(...adWrapper.split(' '));
        adContainer.classList.add(...displayOnMobile.split(' '));

        mobileAdElement.after(adContainer);

        nextPortals.push(
          createPortal(
            <Advertisement
              id={AdvertisementId.MobileInContent}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />,
            adContainer,
          ),
        );
      });

      setPortals(nextPortals);
    }
  }, [contentRef, desktopRepeats, disabled, mobileRepeats]);

  return portals;
}

function isDisabled(disabled: boolean | string[], id: AdvertisementId): boolean {
  return disabled === true || (Array.isArray(disabled) && disabled.includes(id));
}

const adWrapper = tw('flex items-center justify-center');

const displayOnDesktop = tw('hidden md:flex');

const displayOnMobile = tw('md:hidden');

export { useContentAdvertisements };
