import { memo, useRef, forwardRef, ForwardedRef, useImperativeHandle } from 'react';
import { useHtmlScripts } from '@flame-frontend-utils/commons';
import { Helmet } from 'react-helmet-async';
import { useSpecialCleanup } from './useSpecialCleanup';
import { useViewport } from './useViewport';
import { useStyleLock } from './useStyleLock';
import { tw } from '../../../../../styles/tw';

interface SpecialHtmlRendererProps {
  // eslint-disable-next-line react/no-unused-prop-types
  body: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isMobile: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}

const SpecialHtmlRenderer = memo(
  forwardRef(({ body, isMobile, className }: SpecialHtmlRendererProps, ref: ForwardedRef<HTMLDivElement>) => {
    const rootRef = useRef<HTMLDivElement>(null);
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => rootRef.current);

    useHtmlScripts(rootRef, body);
    useSpecialCleanup();

    const viewportContent = useViewport();

    useStyleLock();

    return (
      <>
        {viewportContent ? (
          <Helmet>
            <meta name="viewport" content={viewportContent} />
          </Helmet>
        ) : null}
        {/* Force new stacking context to prevent post elements from overlapping e.g. header */}
        <div ref={rootRef} className={tw('isolate mb-20 mt-0 self-stretch', legacyStyles, className)}>
          {!isMobile ? (
            // eslint-disable-next-line tailwindcss/no-custom-classname
            <main className="article-page container">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <div className="col-wrapper first-wrapper">
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <article className="col-680">
                  {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                  <div className="content">
                    {/* eslint-disable-next-line react/no-danger */}
                    <div itemProp="articleBody" dangerouslySetInnerHTML={{ __html: body }} />
                  </div>
                </article>
              </div>
            </main>
          ) : (
            // eslint-disable-next-line tailwindcss/no-custom-classname
            <main className="page">
              {/* eslint-disable-next-line react/no-danger,tailwindcss/no-custom-classname */}
              <div className="content" dangerouslySetInnerHTML={{ __html: body }} />
            </main>
          )}
        </div>
      </>
    );
  }),
);

const legacyStyles = tw(
  '![&_img]:h-auto bg-[white] [&_.article-page]:mx-auto [&_.article-page]:my-0 [&_.article-page]:w-[1040px] [&_.article-page]:px-[20px] [&_.article-page]:pb-[5px] [&_.article-page]:pt-0 [&_.page]:mx-auto [&_.page]:my-0 [&_.page]:p-[0.3125rem_1.25rem_1.25rem] [&_img]:mx-0 [&_img]:mb-0 [&_img]:mt-[40px] [&_img]:max-w-full',
);

export { SpecialHtmlRenderer };
