import { RefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FlameImageLink, flameImageContainer } from '../../../../../components/FlameImageLink';

export function useLoadFlameImages(flameContainerRef: RefObject<HTMLElement>) {
  const [portals, setPortals] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const flameContainer = flameContainerRef.current;
    if (flameContainer) {
      const flameImages = flameContainer.querySelectorAll('.picture-figure');

      const nextPortals: JSX.Element[] = [];

      flameImages.forEach((flameImage) => {
        const picture = flameImage.querySelector('picture');

        if (flameImage instanceof HTMLElement && picture instanceof HTMLElement) {
          const originalUrl = flameImage.getAttribute('data-original-url');
          if (originalUrl) {
            picture.classList.add(...flameImageContainer.split(' '));

            nextPortals.push(
              createPortal(
                <FlameImageLink url={originalUrl} alt={flameImage.getAttribute('data-alt') ?? ''} />,
                picture,
              ),
            );
          }
        }
      });

      setPortals(nextPortals);
    }
  }, [flameContainerRef]);

  return portals;
}
