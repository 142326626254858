import { useTypedParams } from 'react-router-typesafe-routes';
import { ROUTES } from '../../lib/ROUTES';
import { PostPageContent } from './PostPageContent';
import { usePaidViewReport } from './usePaidViewReport';

const PostPage = () => {
  const { slug } = useTypedParams(ROUTES.POST);

  usePaidViewReport(slug);

  /**
   * We remount the page on every slug change because it's the easiest way to scroll it to top when we switch to another
   * post from withing the page.
   *
   * We remount the page like this because we need to reset apollo queries as well. Otherwise, we will briefly see the
   * previous post right after scroll.
   */
  return <PostPageContent slug={slug} key={slug} />;
};

export default PostPage;
export { PostPage };
