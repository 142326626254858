import { memo, useRef, forwardRef, ForwardedRef, useImperativeHandle } from 'react';
import { useHtmlScripts } from '@flame-frontend-utils/commons';
import { tw } from '../../../../../styles/tw';

interface FlameHtmlRendererProps {
  // eslint-disable-next-line react/no-unused-prop-types
  body: string;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}

const PROSE_MIRROR_VIEW_CLASS = 'ProseMirror-view';

const FlameHtmlRenderer = memo(
  forwardRef(({ body, className }: FlameHtmlRendererProps, ref: ForwardedRef<HTMLDivElement>) => {
    const rootRef = useRef<HTMLDivElement>(null);
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => rootRef.current);

    useHtmlScripts(rootRef, body);

    return (
      <div
        ref={rootRef}
        className={tw('mb-10', PROSE_MIRROR_VIEW_CLASS, className)}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  }),
);

export { FlameHtmlRenderer };
