import { FC } from 'react';
import { CommentsSection } from '../CommentsSection';
import { MediaPost } from '../../types';

interface AdditionalContentProps {
  withComments?: boolean;
  mediaPost: MediaPost;
}

const AdditionalContent: FC<AdditionalContentProps> = ({ mediaPost, withComments }) =>
  withComments ? <CommentsSection slug={mediaPost.slug} commentsCount={mediaPost.commentsCount} /> : null;

export { AdditionalContent };
export type { AdditionalContentProps };
