import { useEffect } from 'react';
import { usePrevious } from '@flame-frontend-utils/commons';
import { matchPath } from 'react-router';
import { useMutation } from '@apollo/client';
import { everyGqlErrorIs } from '@flame-frontend-utils/commons-apollo';
import { ROUTES } from '../../lib/ROUTES';
import { ViewPostBySlugDocument } from './gql/ViewPostBySlug.document';
import { GqlError } from '../../lib/graphql.document';
import { ignoreReject } from '../../lib/ignoreReject';

const READ_WAIT = 10000; // ms

function usePaidViewReport(slug: string) {
  const prevSlug = usePrevious(slug);

  const [viewPost] = useMutation(ViewPostBySlugDocument, {
    context: { disableErrorPopup: everyGqlErrorIs(GqlError.PostNotFound) },
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (prevSlug !== slug) {
      const enrichedPrevSlug = prevSlug ?? getSlugFromReferrer();

      timeoutId = setTimeout(() => {
        void ignoreReject(viewPost({ variables: { slug, referrerSlug: enrichedPrevSlug } }));
      }, READ_WAIT);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [prevSlug, slug, viewPost]);
}

function getSlugFromReferrer(): string | null {
  const referrerLocation = window.document.referrer.match(/^https?:\/\/[^/]+(\/[^?]+)/)?.[1] ?? '';
  const match = matchPath(ROUTES.POST.$path(), referrerLocation);
  if (match) {
    return ROUTES.POST.$deserializeParams(match.params).slug;
  }

  return null;
}

export { usePaidViewReport };
