import { useRef, useState } from 'react';
import { Loader } from '../../../../components/Loader';
import { Advertisement, AdvertisementId } from '../../../../components/Advertisement';
import { Grid } from '../PostContent';
import { HEADER_HEIGHT } from '../../../../styles/fixedSizes';
import { useCommentsQuery } from './hooks';
import { CommentList, CommentSectionHeader, StickyNewComment } from './components';
import { Button, ButtonVariant } from '../../../../components/Button';
import { CommentOrderField, OrderDirection } from '../../../../lib/graphql.document';
import { tw } from '../../../../styles/tw';
import { layout } from '../../../../styles/layout';
import { ignoreReject } from '../../../../lib/ignoreReject';
import { getCommentsSectionId } from '../../lib/getCommentsSectionId';

interface CommentsSectionProps {
  slug: string;
  commentsCount: number;
}

const SUBSEQUENT_COMMENTS_PAGE_SIZE = 20;

const CommentsSection = ({ slug, commentsCount }: CommentsSectionProps) => {
  const [currentSort, setCurrentSort] = useState<CommentOrderField.CreatedAt | CommentOrderField.Popularity>(
    CommentOrderField.Popularity,
  );

  const [currentDirection, seCurrentDirection] = useState<OrderDirection.Desc | OrderDirection.Asc>(
    OrderDirection.Desc,
  );
  const toggleSort = () => {
    switch (currentSort) {
      case CommentOrderField.CreatedAt:
        if (currentDirection === OrderDirection.Desc) {
          setCurrentSort(CommentOrderField.Popularity);
          seCurrentDirection(OrderDirection.Desc);
        } else {
          seCurrentDirection(OrderDirection.Desc);
        }
        break;
      case CommentOrderField.Popularity:
        setCurrentSort(CommentOrderField.CreatedAt);
        seCurrentDirection(OrderDirection.Asc);
        break;
      default:
        setCurrentSort(CommentOrderField.CreatedAt);
        seCurrentDirection(OrderDirection.Desc);
    }
  };

  const { data, fetchMore } = useCommentsQuery(slug, currentSort, currentDirection);
  const { hasNextPage, endCursor } = data ?? {};
  const anchorRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    void ignoreReject(
      fetchMore({ variables: { pagination: { first: SUBSEQUENT_COMMENTS_PAGE_SIZE, after: endCursor } } }),
    );
  };

  return (
    <div className={tw(layout, 'relative min-h-[8.75rem]')}>
      {data ? (
        <Grid
          style={{ '--header-height': `${HEADER_HEIGHT / 16}rem` }}
          id={getCommentsSectionId(slug)}
          className="scroll-mt-[var(--header-height)] lg:pb-4"
        >
          {/* It has to come first in DOM, but render in second column */}
          <section className="order-2 mt-5">
            <CommentSectionHeader
              commentsCount={commentsCount}
              anchorRef={anchorRef}
              currentSort={currentSort}
              currentDirection={currentDirection}
              onSortClick={toggleSort}
            />
            <CommentList comments={data.comments} />
            <StickyNewComment anchor={anchorRef.current} />
            {hasNextPage && (
              <Button variant={ButtonVariant.Outlined} className="lg:mt-4.5" onClick={onClick}>
                Показать еще {Math.min(data.totalCount - data.comments.length, SUBSEQUENT_COMMENTS_PAGE_SIZE) || ''}
              </Button>
            )}
          </section>
          <div className="flex items-start justify-start">
            <Advertisement
              style={{ '--header-height': `${(HEADER_HEIGHT + 25) / 16}rem` }}
              className="sticky top-[var(--header-height)] mt-6 hidden lg:flex"
              id={AdvertisementId.DesktopCommentsSideBanner}
            />
          </div>
        </Grid>
      ) : (
        <Loader className="flex justify-center pt-6" />
      )}
      <div className="border-blood lg:border-b lg:pb-6" />
    </div>
  );
};

export { CommentsSection };
export type { CommentsSectionProps };
export default CommentsSection;
