import { useQuery } from '@apollo/client';
import { useTypedSearchParams } from 'react-router-typesafe-routes';
import { PostCommentsDocument } from '../../gql/PostComments.document';
import { ROUTES } from '../../../../../lib/ROUTES';
import { CommentOrderField, OrderDirection } from '../../../../../lib/graphql.document';

export const useCommentsQuery = (
  slug: string,
  currentSort: CommentOrderField.CreatedAt | CommentOrderField.Popularity,
  currentDirection: OrderDirection.Asc | OrderDirection.Desc,
) => {
  const [{ targetCommentId }] = useTypedSearchParams(ROUTES.POST);
  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(PostCommentsDocument, {
    variables: {
      postIdentity: slug,
      pagination: { first: targetCommentId ? undefined : 5 },
      field: currentSort,
      direction: currentDirection,
      targetId: targetCommentId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const data = currentData || previousData;

  return {
    data: data
      ? {
          totalCount: data.comments.totalCount,
          comments: data.comments.items,
          hasNextPage: data.comments.pageInfo.hasNextPage,
          endCursor: data.comments.pageInfo.endCursor,
        }
      : undefined,
    loading,
    fetchMore,
  };
};
