import { PostComment } from '../../../types';
import { UserPermission } from '../../../../../../lib/graphql.document';
import { User } from '../../../../../../react-hooks/useLoginInfo';
import { DeletedComment, RespondableComment, RestorableComment } from '../../../../../../components/comments';
import { HEADER_HEIGHT } from '../../../../../../styles/fixedSizes';
import { tw } from '../../../../../../styles/tw';
import { hasPermission } from '../../../../../../lib/apollo';

declare module 'react' {
  interface CSSProperties {
    '--header-height'?: string;
  }
}

interface CommentProps {
  user: User;
  comment: PostComment;
  isReplyFormVisible: boolean;
  areChildrenVisible: boolean;
  isChildComment?: boolean;
  toggleReply: () => void;
  onCancelNewComment: () => void;
  toggleChildren: (nextValue?: boolean) => void;
}

const Comment = ({ comment, isChildComment, isReplyFormVisible, user, ...rest }: CommentProps) => {
  const { deleted } = comment;
  const isModerator = hasPermission(user?.permissions ?? [], UserPermission.Moderate);

  return (
    <div
      className={tw(
        'relative -mt-px border-blood px-0 pb-3.5 pt-4 text-xs md:px-0 md:pb-5 md:pt-6 md:text-base [&:not(:nth-child(1))]:border-t',
        isChildComment ? 'ml-9' : 'ml-0',
        !isReplyFormVisible ? 'border-b border-blood' : 'border-none',
      )}
    >
      <div
        style={{ '--header-height': `${HEADER_HEIGHT / 16}rem` }}
        /* eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values */
        className="absolute -top-[var(--header-height)]"
        id={comment.id}
      />
      {deleted &&
        (isModerator ? (
          <RestorableComment comment={comment} {...rest} />
        ) : (
          <DeletedComment comment={comment} {...rest} />
        ))}
      {!deleted && <RespondableComment comment={comment} isReplyFormVisible={isReplyFormVisible} {...rest} />}
    </div>
  );
};

export { Comment };
