import { ReactNode, ElementType, CSSProperties } from 'react';
import { tw } from '../../../../styles/tw';

interface GridProps {
  children: ReactNode;
  as?: ElementType;
  forceMobileLayout?: boolean;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const Grid = ({ as: As = 'div', children, forceMobileLayout, className, style, id }: GridProps) => (
  <As
    id={id}
    style={style}
    className={tw(
      'grid [&>*]:min-w-0',
      !forceMobileLayout &&
        "lg:grid-cols-[1fr_2fr] lg:gap-12 lg:[grid-template-areas:'left_right'] xl:grid-cols-[1fr_1fr]",
      className,
    )}
  >
    {children}
  </As>
);

export { Grid };
