import { formatInTimeZone, toZonedTime, fromZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

const TZ = 'Europe/Moscow';

export const utcToAppZonedTime = (date: Parameters<typeof toZonedTime>[0]): Date => toZonedTime(date, TZ);
export const appZonedTimeToUtc = (date: Parameters<typeof fromZonedTime>[0]): Date => fromZonedTime(date, TZ);

export const formatInAppTimezone = (date: string | number, format: string) =>
  formatInTimeZone(new Date(date), TZ, format, { locale: ru });
