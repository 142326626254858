import { memo, useRef, forwardRef, ForwardedRef, useImperativeHandle } from 'react';
import { useHtmlScripts } from '@flame-frontend-utils/commons';
import { HtmlProps } from '../types';
import { tw } from '../../../../../styles/tw';

const VerstkaHtml = memo(
  forwardRef(({ body, className }: HtmlProps, ref: ForwardedRef<HTMLDivElement>) => {
    const rootRef = useRef<HTMLDivElement>(null);
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => rootRef.current);

    useHtmlScripts(rootRef, body);

    return (
      <div
        ref={rootRef}
        className={tw(
          /* Force new stacking context to prevent post elements from overlapping e.g. header */
          'isolate mb-20 mt-0 self-stretch',
          className,
        )}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  }),
);

export { VerstkaHtml };
