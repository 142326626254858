import { useEffect } from 'react';

export function useSpecialCleanup() {
  useEffect(
    () => () => {
      document.head.querySelectorAll('link[rel="stylesheet"]').forEach((styleElement) => {
        if ((styleElement as HTMLLinkElement).href.includes('specials.spletnik.ru')) {
          styleElement.remove();
        }
      });
    },
    [],
  );
}
